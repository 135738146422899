// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
require('jszip')
require('datatables.net-bs4')
require('datatables.net-buttons-bs4')
require('datatables.net-buttons/js/buttons.html5')
require('datatables.net-buttons/js/buttons.print')
require("daterangepicker")
require("../src/template")
require("../src/custom")
require('../src/nested-forms/addFields')
require('../src/nested-forms/removeFields')
require('packs/select2_setup')
require('packs/deposit_account')
import { initKanbanSortable } from 'packs/initSortable';
document.addEventListener('turbolinks:load', () => {
  const kanbanUls = document.querySelectorAll(".kanban-col");
  if (kanbanUls) {
    initKanbanSortable(kanbanUls);
  }
});

import './bootstrap_js_files'
// import './rich_text_area_modifer'
import "@fortawesome/fontawesome-free/js/all";
import $ from 'jquery';

global.moment = require('moment');

global.$ = jQuery;
global.toastr = require("toastr")


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


require("trix")
require("@rails/actiontext")
require('trix/dist/trix.css')

import I18n from 'i18n-js/index.js.erb'
global.I18n = I18n;

require('pdfmake/build/pdfmake');
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


$(document).ready(function() {
  $(".non-nagative-value").on("keyup keypress", function (event) {
    return !(event.which < 46 || event.which > 57)
  });
});