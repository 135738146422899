import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

window.select2DefaultOptions = {
  tags: true,
  minimumResultsForSearch: 1,
  tokenSeparators: [',', ' '],
  width: '100%',
};

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => { 
window.addEventListener('turbolinks:load', () => {
  $('.select2').select2(select2DefaultOptions);

  $('.select2.member_select2').select2(select2DefaultOptions).on("change", function (e) {
    let name = this.name.replace("member_id", "minor_id");
    let id = this.id.replace("member_id", "minor_id");
    let sname = this.name.replace("member_id", "payment_transactions_attributes");
    let sid = this.id.replace("member_id", "payment_transactions_attributes_0_saving_account_id");

    $.ajax({
      type: 'GET',
      url: '/fd-mis-accounts/fetch_minor',
      dataType: 'json',
      data: { 'id' : $(this).val()},
      success: function(data) {
        if (data.minors.length > 0) {
          var minor = "<select class ='form-control minor' name='"+name+"' id='"+id+"'><option></option>"
          for(var i=0 ; i<data.minors.length;i++) {
            minor+="<option value='"+data.minors[i].id+"'>"+data.minors[i].first_name+"</option>";
          }
          $('#minor_selection').html(minor);
        } else {
          $('#minor_selection').html("No minor found");
        }

        if (data.saving_accounts.length > 0){
          let saving_account = "<select class ='form-control saccount' name='"+""+sname+"[0][saving_account_id]"+"' id='"+sid+"'><option></option>"
          for(var i=0 ; i<data.saving_accounts.length;i++){
            saving_account+="<option value='"+data.saving_accounts[i].id+"'>#"+data.saving_accounts[i].id+" "+data.saving_accounts[i].account_number+"</option>";
          }
          $('#saccount_selection').html(saving_account);
        }
        else {
          $('#saccount_selection').html("No saving accounts found");
        }
      }
    });

    // Update nominee inputs
    $.ajax({
      type: 'GET',
      url: '/memberable_nominees/' + $(this).val(),
      dataType: 'json',
      success: function(data) {
        $("#nominee_display #nominee_name_input").val(data.nominee_name);
        $("#nominee_display #nominee_relation_input").val(data.nominee_relation);
        $("#nominee_display #nominee_mobile_no_input").val(data.nominee_mobile_no);
        $("#nominee_display #nominee_aadhar_no_input").val(data.nominee_aadhar_no);
        $("#nominee_display #nominee_voter_id_input").val(data.nominee_voter_id);
        $("#nominee_display #nominee_pan_no_input").val(data.nominee_pan_no);
        $("#nominee_display #nominee_ration_card_no_input").val(data.nominee_ration_card_no);
        $("#nominee_display #nominee_address_input").val(data.nominee_address);
      }
    });
  });
});
