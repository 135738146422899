import Sortable from 'sortablejs';
import Rails from "@rails/ujs";

const initKanbanSortable = (ulElements) => {
    const saveKanbanBinded = saveKanban.bind(null, ulElements);
    ulElements.forEach((ul) => {
        new Sortable(ul, {
            group: 'kanban', // set both lists to same group
            animation: 300,
            onEnd: saveKanbanBinded
        });
    });
};

const saveKanban = (ulElements) => {
    const kanbanIds = { "columns": [] };
    ulElements.forEach(ul => {
        const itemIds = [];
        ul.querySelectorAll(".kanban-col-item")
            .forEach(item => itemIds.push(Number.parseInt(item.dataset.itemId, 10)));
        kanbanIds.columns.push({
            'id': Number.parseInt(ul.dataset.colId, 10),
            'itemIds': itemIds
        });
    });
    const kanbanId = document.querySelector(".kanban").dataset.id;
    const formData = new FormData();
    formData.append('task[kanbanIds]', JSON.stringify(kanbanIds));
    Rails.ajax({
        url: `/task-management/tasks/kanban_sort`,
        type: "patch",
        data: formData
    })
}

export { initKanbanSortable };
