var min_amount = 0;
var payout = {};

$(document).on('turbolinks:load', function() {
  // Hack solution to fix the issue with change event of select2
  $("#plan_name").on('change', function(){
    enable_submit_button();
    if($("#plan_name").val() != "") {
      $.ajax({
        type: 'GET',
        url: '/fd-mis-accounts/fetch_fd_plan',
        dataType: 'json',
        data: { 'id' : $("#plan_name").val()},
        success: function(data) {
          $('#tenure_type').val(data.plan.tenure_type);
          $('#period').val(data.plan.period);
          $('#senior_citizen_interest_rate').val(data.plan.senior_citizen_interest_rate);
          $('#lady_interest_rate').val(data.plan.lady_interest_rate);
          $('#ex_service_interest_rate').val(data.plan.ex_service_interest_rate);
          $('#interest_payout').val(data.plan.interest_payout);
          $('#interest_rate').val(data.plan.interest_rate);
          $("#amount_content").removeClass('hidden');
          $("#amount").removeClass('hidden');
          $("#amount").text(data.plan.min_amount);
          $(".autofilled").attr("disabled", true);
          $("#min_amount").attr("min", data.plan.min_amount);
        }
      });
    } else {
      plan_change_event();
    }
  });

  $(".dep_plan").on('select2:select', function() {
    let depositPlanId = $(this).val();

    if(depositPlanId != "") {
      $.ajax({
        type: 'GET',
        url: '/fd-mis-accounts/fetch_fd_plan',
        dataType: 'json',
        data: { 'id' : depositPlanId},
        success: function(data) {
          update_plan_details(data.plan)
          $("#amount_content").removeClass('hidden');
          $("#amount_needed").removeClass("hidden")
          $("#amount").removeClass('hidden');
          $("#amount").text(data.plan.min_amount);
          min_amount = data.plan.min_amount;
          $(".form_amt").attr("min", data.plan.min_amount);
          $('.less_amt').attr('disabled', false)

          if(data.plan.min_amount <= parseFloat($("#balance").val())){
            $("#amount_needed").text("Eligible for upgrade");
            $(".upgrade_btn").attr('disabled', false);
          } else {
            diff = data.plan.min_amount -  parseFloat($("#balance").val())
            $("#amount_needed").text("Please deposit Rs." + diff + "to upgrade");
            $(".upgrade_btn").attr('disabled', true);
          }
        }
      });
    }
  });

  $(".autofilled").attr("disabled", true);
  $("#payout_button").on("click", function() {
    $("#payout_body").html("")
    $("#payout").removeClass('hidden');
    for(i=1; i<= Object.keys(payout).length; i++) {
      for(j=0;j< payout[i].length; j++){
        value = payout[i][j];
        period = value.new_start_date + " - " + value.new_end_date;
        $("#payout_body").append("<tr><td>"+value.count+"</td><td>"+period+"</td><td>"+value.no_of_days+"</td><td>"+parseFloat(value.principal).toFixed(2)+"</td><td>"+parseFloat(value.interest).toFixed(2)+"</td><td>"+parseFloat(value.tds_amount).toFixed(2)+"</td><td>"+parseFloat(value.net_interest).toFixed(2)+"</td><td>"+value.next_end_day+"</td></tr>");
      }
    }
  });

  $("#senior").on("click", function() {
    enable_submit_button();
    if(this.checked){
      $("#senior_form").removeClass('hidden');
    }
    else {
      $("#senior_form").addClass('hidden');
    }
  });

  $('.account_type').on('change', function() {
    var account_type = $('.account_type').val();
    window.location = "/single_collections/single_collections/new?account_type=" + account_type;
  });

  $("#calculator-fd-mis").on('submit', function(e){
    e.preventDefault();
    $("#payout_body").html("");
    $("#payout").addClass('hidden');
    form_disabled = $(".autofilled").is(":disabled");
    $(".autofilled").attr("disabled", false);
    formdata = $("#calculator-fd-mis").serializeArray();
    if(form_disabled) {
      $(".autofilled").attr("disabled", true);
    }
    data = {};
    $(formdata ).each(function(index, obj){
        data[obj.name] = obj.value;
    });
    enable_submit_button();
    $.ajax({
      type: 'GET',
      url: '/fd-mis-accounts/interest_calculation',
      dataType: 'json',
      data: { 'data' : data},
      success: function(data) {
        $("#f_amount").text(data.result.principal_amount);
        $("#f_interest").text(data.result.total_interest);
        $("#f_mat_amount").text(data.result.total_amount_payable);
        $("#f_tds").text(data.result.total_tds);
        $("#f_net_interest").text(data.result.total_net_interest);
        $("#f_date").text(data.result.maturity_date);
        $("#f_rate").text(data.result.rate + "%");
        $("#f_tenure").text(data.result.tenure);
        $("#pay_info").removeClass("hidden");
        $('#submit').attr('disabled', false);
        payout = data.payouts;
        enable_submit_button();
      }
    });
  });

  $("#tds").on('click', function(){
    enable_submit_button();
  });

  if($(".nominee").is(':checked')) {
    $("#nominee_display").removeClass('hidden');
  }

  $(".payment_mode").on("change", function(e) {
    payment_mode_visibility($(".payment_mode").val());
  });

  $(document).ready(function(){
    if(typeof $(".deposit_withdraw_payment_mode").val() !== 'undefined') {
      payment_mode_visibility($(".deposit_withdraw_payment_mode").val());
    } else if (typeof $(".payment_mode").val() !== 'undefined') {
      payment_mode_visibility($(".payment_mode").val());
    }
  });

  $(".deposit_withdraw_payment_mode").on("change", function(e) {
    payment_mode_visibility(e.currentTarget.value);
  });


  function jointMemberAccount(is_checked){
    if(is_checked) {
      $("#joint_member").removeClass('hidden');
    } else {
      $("#joint_member").addClass('hidden');
    }
  }

  $(".jt_acn").on('click', function() {
    jointMemberAccount(this.checked)
  });

  $(document).ready(function(){
    jointMemberAccount($('.jt_acn').is(':checked'))
  });

  function deposit_manual_plan(is_manual) {
    if(is_manual) {
      console.log()
      $(".manually_plan").hide();
      $(".manually_deposit_plan").show();

      $('.deposit-plan-id').removeAttr('required')
      $('.deposit-plan-code').attr("required", true);
      $('.deposit-plan-name').attr("required", true);
      $('.deposit-plan-min-amount').attr("required", true);
      $('.deposit-plan-min-monthly-avg-bal').attr("required", true);
      $('.deposit-plan-minimum-lockin-period').attr("required", true);
      $('.deposit-plan-interest-lockin-period').attr("required", true);
      $('.deposit-plan-interest-rate').attr("required", true);
      $('.deposit-plan-period').attr("required", true);
      $('.payment-transaction-saving-account-id').removeAttr("required");
    } else {
      $(".manually_plan").show();
      $(".manually_deposit_plan").hide();

      $('.payment-transaction-saving-account-id').attr("required", true);
      $('.deposit-plan-id').attr("required", true);
      $('.deposit-plan-code').removeAttr("required");
      $('.deposit-plan-name').removeAttr("required");
      $('.deposit-plan-min-amount').removeAttr("required");
      $('.deposit-plan-min-monthly-avg-bal').removeAttr("required");
      $('.deposit-plan-minimum-lockin-period').removeAttr("required");
      $('.deposit-plan-interest-rate').removeAttr("required");
      $('.deposit-plan-interest-lockin-period').removeAttr("required");
      $('.deposit-plan-period').removeAttr("required");
    }
  }

  $(".manual_plan_add").on('click', function() {
    deposit_manual_plan(this.checked)
  })

  $(document).ready(function() {
    deposit_manual_plan($('.manual_plan_add').is(':checked'))
    if(!$('.manual_plan_add').is(':checked')){
      $('.payment-transaction-saving-account-id').removeAttr("required");
      $('.deposit-plan-id').attr("required", true);
      $('.deposit-plan-code').removeAttr("required");
      $('.deposit-plan-name').removeAttr("required");
      $('.deposit-plan-min-amount').removeAttr("required");
      $('.deposit-plan-min-monthly-avg-bal').removeAttr("required");
      $('.deposit-plan-minimum-lockin-period').removeAttr("required");
      $('.deposit-plan-interest-rate').removeAttr("required");
      $('.deposit-plan-interest-lockin-period').removeAttr("required");
      $('.deposit-plan-period').removeAttr("required");
    }
  });

  function saving_manual_plan(is_manual) {
    if(is_manual){
      $(".manually_saving_plan").hide();
      $(".manually_saving_plan_render").show();

      $('#saving_account_deposit_plan_id').removeAttr('required')
      $('#saving_plan_plan_code').attr('required', true)
      $('#saving_plan_plan_name').attr('required', true)
      $('#saving_plan_min_amount').attr('required', true)
      $('#saving_plan_min_monthly_avg_bal').attr('required', true)
      $('#saving_plan_minimum_lockin_period').attr('required', true)
      $('#saving_plan_interest_lockin_period').attr('required', true)
      $('#saving_plan_interest_rate').attr('required', true)
    } else {
      $(".manually_saving_plan").show();
      $(".manually_saving_plan_render").hide();

      $('#saving_account_deposit_plan_id').attr('required', true)
      $('#saving_plan_plan_code').removeAttr('required')
      $('#saving_plan_plan_name').removeAttr('required')
      $('#saving_plan_min_amount').removeAttr('required')
      $('#saving_plan_min_monthly_avg_bal').removeAttr('required')
      $('#saving_plan_minimum_lockin_period').removeAttr('required')
      $('#saving_plan_interest_lockin_period').removeAttr('required')
      $('#saving_plan_interest_rate').removeAttr('required')
    }
  }

  $(".manual_saving_plan_add").on('click', function() {
    saving_manual_plan(this.checked)
  })

  $(document).ready(function() {
    saving_manual_plan($('.manual_saving_plan_add').is(':checked'))
    if($('.manual_saving_plan_add').is(':checked')){

      $('#saving_account_deposit_plan_id').removeAttr('required')
      $('#saving_plan_plan_code').removeAttr('required')
      $('#saving_plan_plan_name').removeAttr('required')
      $('#saving_plan_min_amount').removeAttr('required')
      $('#saving_plan_min_monthly_avg_bal').removeAttr('required')
      $('#saving_plan_minimum_lockin_period').removeAttr('required')
      $('#saving_plan_interest_lockin_period').removeAttr('required')
      $('#saving_plan_interest_rate').removeAttr('required')
    }
  })

  $(".nominee").on('click', function() {
    if(this.checked) {
      $("#nominee_display").removeClass('hidden');
    } else {
      $("#nominee_display").addClass('hidden');
    }
  });

  $(".form_amt").on('change', function() {
    $(".trans_amt").val($(".form_amt").val());
  });

  $(".less_amt").on("click", function() {
    if(this.checked) {
      $(".form_amt").removeAttr("min", min_amount);
      $(".form_amt").attr("max", min_amount);
    } else {
      $(".form_amt").attr("min", min_amount);
      $(".form_amt").removeAttr("max", min_amount);
    }
  });

  $(".nominee").on('click', function() {
    if(this.checked) {
      $("#nominee_display").removeClass('hidden');
    } else {
      $("#nominee_display").addClass('hidden');
    }
  });

  $(".debit_other_charge").on("click", function() {
    if($("#debit_other_charge").hasClass("hidden")) {
      $("#debit_other_charge").removeClass('hidden');
    } else {
      $("#debit_other_charge").addClass('hidden');
    }   
  });

  $(".upgrade_accn").on("click", function() {
    if($("#upgrade_accn").hasClass("hidden")) {
      $("#upgrade_accn").removeClass('hidden');
    } else {
      $("#upgrade_accn").addClass('hidden');
    }   
  });

  $("#has_joint_account").on("click", function() {
    if($("#upgrade_account_details").hasClass("hidden")) {
      $("#upgrade_account_details").removeClass('hidden');
    } else {
      $("#upgrade_account_details").addClass('hidden');
      $("#joint_account_member_id").val('0').change();
    }   
  });

  $(".close_accn").on("click", function() {
    if($("#close_accn").hasClass("hidden")) {
      $("#close_accn").removeClass('hidden');
    } else {
      $("#close_accn").addClass('hidden');
    }   
  });

  $(".mature_pay").on("click", function() {
    if($("#mature_pay").hasClass("hidden")) {
      $("#mature_pay").removeClass('hidden');
    } else {
      $("#mature_pay").addClass('hidden');
    }   
  });

  $(".final_pay").on("click", function() {
    if($("#final_pay").hasClass("hidden")) {
      $("#final_pay").removeClass('hidden');
    } else {
      $("#final_pay").addClass('hidden');
    }   
  });

  $(".ded_rev_tds").on("click", function() {
    if($("#ded_rev_tds").hasClass("hidden")) {
      $("#ded_rev_tds").removeClass('hidden');
    } else {
      $("#ded_rev_tds").addClass('hidden');
    }   
  });
  
  $(".trans").on("click", function() {
    if($("#trans").hasClass("hidden")) {
      $("#trans").removeClass('hidden');
    } else {
      $("#trans").addClass('hidden');
    }   
  });

  $(".remove_accn").on("click", function() {
    if($("#remove_accn").hasClass("hidden")) {
      $("#remove_accn").removeClass('hidden');
    } else {
      $("#remove_accn").addClass('hidden');
    }   
  });

  $(".fore_close").on("click", function() {
    if($("#fore_close_form").hasClass("hidden")) {
      $("#fore_close_form").removeClass('hidden');
    } else {
      $("#fore_close_form").addClass('hidden');
    }   
  });

  $(".minor_btn").on("click", function() {
    if($("#minor").hasClass("hidden")) {
      $("#minor").removeClass('hidden');
    } else {
      $("#minor").addClass('hidden');
    }   
  });

  $(".nominee_btn").on("click", function() {
    if($("#nominee").hasClass("hidden")) {
      $("#nominee").removeClass('hidden');
      if($(".nominee").is(':checked')) {
        $("#nominee_display").removeClass('hidden');
      }
    } else {
      $("#nominee").addClass('hidden');
    }
  });

  $(".payout").on("click", function() {
    if($("#payout").hasClass("hidden")) {
      $("#payout_body").html("");
      $("#payout").removeClass('hidden');
      $.ajax({
        type: 'GET',
        url: '/fd-mis-accounts/payout_calculation',
        dataType: 'json',
        data: { 'account_id' : this.id},
        success: function(data) {
          payout = data.payouts;
          fd_status = data.fd_status;
          flag = 0
          for(i=0; i< payout.length; i++) {
            actions = "";
            value = payout[i];
            status = value.is_processed ? "PAID" : ""
            is_processed = value.is_processed ? "YES" : "NO"
            end_date = new Date(value.end_date);
            end_date.setDate(end_date.getDate() + 1)
            due_date = end_date
            if(i == 0) {
              if(!(value.is_processed) && new Date() > due_date && fd_status){
                actions = "<button data-confirm='Are you sure you want to process?' onclick='process_payout("+value.id+")'>PROCESS</button>";
                flag = 1;
              }
            } else if(!(value.is_processed) && flag == 0 && new Date() > due_date && fd_status) {
              actions = "<button data-confirm='Are you sure you want to process?' onclick='process_payout("+value.id+")'>PROCESS</button>";
              flag = 1;
            }
            period = new Date(value.start_date).toDateString() + " - " + new Date(value.end_date).toDateString();
            $("#payout_body").append("<tr><td>"+value.year+"</td><td>"+period+"</td><td>"+value.no_of_days+"</td><td>"+parseFloat(value.principal).toFixed(2)+"</td><td>"+parseFloat(value.interest).toFixed(2)+"</td><td>"+parseFloat(value.tds_amount).toFixed(2)+"</td><td>"+parseFloat(value.net_interest).toFixed(2)+"</td><td>"+due_date.toDateString()+"</td><td>"+status+"</td><td>"+is_processed+"</td><td>"+actions+"</td></tr>");
          }
        }          
      });
    } else {
      $("#payout").addClass('hidden');
    }
  });


  $(".inst_button").on("click", function() {
    if($("#installment").hasClass("hidden")) {
      $("#installment_body").html("");
      $("#installment").removeClass('hidden');
      $.ajax({
        type: 'GET',
        url: '/rd-dd-accounts/fetch_installments',
        dataType: 'json',
        data: { 'account_id' : this.id},
        success: function(data) {
          payout = data.payouts;
          fd_status = data.fd_status;
          flag = 0
          for(i=0; i< payout.length; i++) {
            status = "";
            value = payout[i];
            status = value.is_processed ? "PAID" : ""
            is_processed = value.is_processed ? "YES" : "NO"
            due_date = value.due_date
            if(new Date() >= new Date(value.due_date) && status != "PAID") {
              status = "DUE"
            }

            $("#installment_body").append("<tr><td>"+value.year+"</td><td>"+value.principal+"</td><td>"+value.due_date+"</td><td>"+status+"</td></tr>");
          }
        }          
      });
    }
    else {
      $("#installment").addClass('hidden');
    }
  });

  $(".upgrade").on('click', function() {
    if(this.checked) {
      $("#upgrade").removeClass("hidden");
    }
    else {
      $("#upgrade").addClass("hidden");
    }
  });

  $(document).ready(function() {
    var isChecked = localStorage.getItem("authorisedCheckboxChecked");
  
    if (isChecked === "true") {
      $(".authorised_sign").prop("checked", true);
      $("#authorised_sign").removeClass("hidden");
    }

    $(".authorised_sign").on('click', function() {
      if (this.checked) {
        localStorage.setItem("authorisedCheckboxChecked", "true");
        $("#authorised_sign").removeClass("hidden");
      } else {
        localStorage.removeItem("authorisedCheckboxChecked");
        $("#authorised_sign").addClass("hidden");
      }
    });
  });

  $("#ded_rev_tds").on('submit', function(e) {
    e.preventDefault();
    formdata = $("#ded_rev_tds").serializeArray();
    data = {};
    $(formdata ).each(function(index, obj) {
        data[obj.name] = obj.value;
    });
    $.ajax({
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'POST',
      url: '/fd-mis-accounts/deduct_reverse_tds',
      dataType: 'json',
      data: { 'payment_transaction' : data, 'account_id': $("#account_id").val()},
      success: function(data) {
        if(data.result == "Success") {
          location.reload();
        }
      }
    });
  });

  $("#payment_mode").on("change", function(e) {
    payment_mode_visibility($("#payment_mode").val());
  })

  $("#final_pay").on('submit', function(e){
    e.preventDefault();
    formdata = $("#final_pay").serializeArray();
    data = {};
    $(formdata ).each(function(index, obj){
        data[obj.name] = obj.value;
    });
    $.ajax({
      type: 'POST',
      url: '/fd-mis-accounts/closure_final_payment',
      dataType: 'json',
      data: { 'payment_transaction' : data, 'account_id': $("#account_id").val(), closure_id: $("#closure_id").val()},
      success: function(data) {
        if (data.result == "Success") {
          location.reload();
        }
      }
    });
  });

  $("#mature_pay").on('submit', function(e) {
    e.preventDefault();
    formdata = $("#mature_pay").serializeArray();
    data = {};
    $(formdata ).each(function(index, obj) {
      data[obj.name] = obj.value;
    });
    $.ajax({
      type: 'GET',
      url: '/fd-mis-accounts/pay_mature_amount',
      dataType: 'json',
      data: { 'payment_transaction' : data, 'account_id': $("#account_id").val()},
      success: function(data) {
        if(data.result == "Success") {
          location.reload();
        }
      }
    });
  });

  $(".amt").on('change', function(){
  net_amount = parseFloat($("#balance").val()) + parseFloat($("#interest_to_be_paid").val()) - parseFloat($("#tds_deduction").val()) - parseFloat($("#penal_charges").val()) - parseFloat($("#cancellation_charges").val());
    $("#net_amount").val(net_amount);
  })

  $('.loan-charge-amount').on('keyup keypress', function(){
    const parseValueToFloat = (selector) => parseFloat($(selector).val()) || 0.0;

    let remainingAmount = parseValueToFloat('#loan_account_closure_remaining_amount');
    let interestAmount = parseValueToFloat('#loan_account_closure_accrued_interest_amount');
    let overdueAmount = parseValueToFloat('#loan_account_closure_overdue_amount');
    let preClosureCharge = parseValueToFloat('#loan_account_closure_pre_closure_charge');
    let closureDiscount = parseValueToFloat('#loan_account_closure_closure_discount');
    let roundingAmount = parseValueToFloat('#loan_account_closure_rounding_amount');

    if ((overdueAmount + preClosureCharge) < (closureDiscount + roundingAmount)) {
      alert("The value of B(Closure Discount + Rounding Off) should not be greater than A(Overdue Amounts + Pre Closure Charges)!");
      $('#loan_account_closure_closure_discount').val(0.0);
      $('#loan_account_closure_rounding_amount').val(0.0);
      return;
    } else {
      netAmount = remainingAmount + interestAmount + overdueAmount + preClosureCharge - closureDiscount - roundingAmount;
      $("#net-amount").val(netAmount);
    }
  })

  $(".autofilled").on("change", function() {
    enable_submit_button();
  })

  $("#min_amount").on("change", function() {
    enable_submit_button();
  })

  function enable_submit_button() {
    $('#submit').attr('disabled', false);
  }

  $("#manual").on("click", function() {
    enable_submit_button();
    if(this.checked){
     $("#plan_name").val("");
     $("#plan_name").attr("disabled", true);
     plan_change_event();
    }
    else {
      $("#plan_name").val("");
      $("#plan_name").attr("disabled", false);
    }
  });
});

function plan_change_event() {
  $(".autofilled").val('');
  $("#amount_content").addClass('hidden');
  $("#amount").addClass('hidden');
  $("#amount").text('');
  $(".autofilled").attr("disabled", false);
  $("#pay_info").addClass("hidden");
  $("#min_amount").attr("min", 0);
}

function payment_mode_visibility(payment_mode) {
  if(payment_mode == "cash"){
    $(".cheque-details").addClass("hidden");
    $(".online-transfer").addClass("hidden");
    $(".cash-denomination").removeClass("hidden");
    $(".saving-account-details").addClass("hidden");
  } 
  else if(payment_mode == "cheque") {
    $(".cheque-details").removeClass("hidden");
    $(".online-transfer").addClass("hidden");
    $(".cash-denomination").addClass("hidden");
    $(".saving-account-details").addClass("hidden");

  }
  else if(payment_mode == "saving_account") {
    $(".cheque-details").addClass("hidden");
    $(".online-transfer").addClass("hidden");
    $(".cash-denomination").addClass("hidden");
    $(".saving-account-details").removeClass("hidden");

  }
  else {
    $(".cheque-details").addClass("hidden");
    $(".online-transfer").removeClass("hidden");
    $(".cash-denomination").addClass("hidden");
    $(".saving-account-details").addClass("hidden");

  }
}

function update_plan_details(plan) {
  $('#plan_code').text(plan.plan_code)
  $('#plan_name').text(plan.plan_name)
  $('#minimum_lockin_period').text(plan.minimum_lockin_period)
  $('#interest_lockin_period').text(plan.interest_lockin_period)
  $('#min_amount').text(plan.min_amount)
  $('#interest_rate').text(plan.interest_rate)
  $('#tenure_type').text(plan.tenure_type)
  $('#period').text(plan.period)
  $('#other_info_lock_in_amount').text(plan.other_info.lock_in_amount)
  $('#other_info_min_monthly_avg_bal').text(plan.other_info.min_monthly_avg_bal)
  $('#senior_citizen_interest_rate').text(plan.senior_citizen_interest_rate)
  $('#lady_interest_rate').text(plan.lady_interest_rate)
  $('#ex_service_interest_rate').text(plan.ex_service_interest_rate)
}

function process_payout(id) {
  $.ajax({
    type: 'GET',
    url: '/fd-mis-accounts/process_payout',
    dataType: 'json',
    data: { 'payout_id' : id},
    success: function(data) {
      if(data.result == "Success") {
        location.reload();
      }
    }
  });
}
