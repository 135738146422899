window.numberToWords = function(number) {
  let wordsList1 = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  let wordsList2 = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

  if ((number = number.toString()).length > 9) return 'overflow';
  let num = ('000000000' + number).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if(!num) return;
  let str = '';

  str += (num[1] != 0) ? (wordsList1[Number(num[1])] || wordsList2[num[1][0]] + ' ' + wordsList1[num[1][1]]) + 'crore ' : '';
  str += (num[2] != 0) ? (wordsList1[Number(num[2])] || wordsList2[num[2][0]] + ' ' + wordsList1[num[2][1]]) + 'lakh ' : '';
  str += (num[3] != 0) ? (wordsList1[Number(num[3])] || wordsList2[num[3][0]] + ' ' + wordsList1[num[3][1]]) + 'thousand ' : '';
  str += (num[4] != 0) ? (wordsList1[Number(num[4])] || wordsList2[num[4][0]] + ' ' + wordsList1[num[4][1]]) + 'hundred ' : '';
  str += (num[5] != 0) ? '' + (wordsList1[Number(num[5])] || wordsList2[num[5][0]] + ' ' + wordsList1[num[5][1]]) : '';

  return str.trim();
};

// initlize datatables

document.addEventListener('turbolinks:load', function() {
  // $('.data-table thead tr')
  //   .clone(true)
  //   .addClass('filters')
  //   .appendTo('.data-table thead');

	var table = $('.data-table').DataTable({
    dom: '<"d-flex justify-content-between"<"table-length-wrapper"l>B>fr<"table-wrapper"t><"d-flex justify-content-between"i<"table-pagination-wrapper"p>>',
    fixedHeader: true,
    searching: false,
    buttons: [
      {
        className: 'table-button table-scroll-btn mr-2',
        text: "<i class='las la-angle-left'></i>",
        init: function(api, node, config) {
          $(node).removeClass('btn btn-secondary');
        },
        action: function ( e, dt, node, config ) {
          $('.table-wrapper').animate({ scrollLeft: "-=100px" }, "slow");
        }
      },
      {
        className: 'table-button table-scroll-btn ml-2',
        text: "<i class='las la-angle-right'></i>",
        init: function(api, node, config) {
          $(node).removeClass('btn btn-secondary');
        },
        action: function ( e, dt, node, config ) {
          $('.table-wrapper').animate({ scrollLeft: "+=100px" }, "slow");
        }
      },
      {
        extend: 'pdf',
        className: 'btn btn-sm table-button',
        text: "<i class='fas fa-file-pdf'></i>",
        init: function(api, node, config) {
          $(node).removeClass('btn-secondary');
        }
      },
      {
        extend: 'csv',
        className: 'btn btn-sm table-button',
        text: "<i class='fas fa-file-csv'></i>",
        init: function(api, node, config) {
          $(node).removeClass('btn-secondary');
        }
      },
      {
        extend: 'print',
        className: 'btn btn-sm table-button',
        text: "<i class='fas fa-print'></i>",
        init: function(api, node, config) {
           $(node).removeClass('btn-secondary');
        }
      }
    ],
    initComplete: function () {
      var api = this.api();
      // For each column
      api.columns().eq(0).each(function (colIdx) {
        // Set the header cell to contain the input element
        var cell = $('th').eq(
            $(api.column(colIdx).header()).index()
        );
        var title = $(cell).text();

        if(cell.hasClass('search')) {
          $(cell).append('<div><input type="text" data-index="' + colIdx + '" placeholder="' + title + '" class="form-control table-search-input w-75 m-0"/><i class="las la-search"></i></div>');
        } else {
          // cell.addClass('mb-4');
        }

        // On every keypress in this input
        var that = this;
        $(
        	'input',
           $('th').eq($(api.column(colIdx).header()).index())
        ).off('keyup change').on('keyup change', function (e) {
          e.stopPropagation();

          // Get the search value
          var regexr = '({search})'; //$(this).parents('th').find('select').val();

          var cursorPosition = this.selectionStart;
          // Search the column for that value
          that.search(this.value).draw();
          $(this).focus()[0].setSelectionRange(cursorPosition, cursorPosition);
        });
      });
    },
    language: I18n.t('datatable')
  });

  // $('.table-search-input').on( 'keyup', function () {
  //   let colIdx = $(this).data('index');

  //   table
  //     .columns(colIdx)
  //     .search(this.value)
  //     .draw();
  // });

  $(document).on("click", ".sidebar-nav-link", function(e) {
    let parentNavElement = $(this).parents(".sidebar-nav");
    localStorage.setItem("activeSidebarNav", parentNavElement.data("navigation"))
  });

  let activeSidebarNav = localStorage.getItem("activeSidebarNav");
  if(activeSidebarNav) {
    $(".sidebar-nav").each(function() {
      if(activeSidebarNav == $(this).data("navigation")) {
        $(this).addClass("active");
      }
    });
  } else {
    $(".sidebar-nav[data-navigation='dashboard'").addClass("active");
  }
});

// dependent dropdown

function changeSubBranch(branch, newBranch, selectedSubBranch){
  if (branch != undefined && branch.length != 0) {
    $.ajax({
      url: "/company/sub_branches",
      method: "GET",
      dataType: "json",
      data: {branch_id: branch},
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        var subBranches = response["company_sub_branches"];
        $(`${newBranch}`).empty();
        $(`${newBranch}`).append('<option>Select One</option>');
        for(var i = 0; i < subBranches.length; i++){
          if (selectedSubBranch != undefined && selectedSubBranch.length != 0 && selectedSubBranch != false && selectedSubBranch == subBranches[i]["id"]){
            $(`${newBranch}`).append('<option selected="selected" value=' + subBranches[i]["id"] + '>' +subBranches[i]["sub_branch_name"] + '</option>');
          } else {
            $(`${newBranch}`).append('<option value=' + subBranches[i]["id"] + '>' +subBranches[i]["sub_branch_name"] + '</option>');
          }
        }
      }
    });
  }
}

// multiple branch with sub branch select
function changeSubBranchMultiple(branch, newBranch, selectedSubSranch){
  if (branch != undefined && branch.length != 0) {
    $.ajax({
      url: "/company/sub_branches/list_sub_branches",
      method: "GET",
      dataType: "json",
      data: {branch_id: branch},
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        var subBranches = response["company_sub_branches"];
        $(`${newBranch}`).empty();
        $(`${newBranch}`).append('<option>Select One</option>');
        for(var i = 0; i < subBranches.length; i++){
          if (selectedSubSranch != undefined && selectedSubSranch.length != 0 && selectedSubSranch != false && selectedSubSranch.includes(`${subBranches[i]["id"]}`)){
            $(`${newBranch}`).append('<option selected="selected" value=' + subBranches[i]["id"] + '>' +subBranches[i]["sub_branch_name"] + '</option>');
          } else {
            $(`${newBranch}`).append('<option value=' + subBranches[i]["id"] + '>' +subBranches[i]["sub_branch_name"] + '</option>');
          }
        }
      }
    });
  }
}

$(document).ready(function(){
  $(".branch-dropdown").on('change', function(){
    var branch = $(this).val();
    var newBranch = $(this).data("target")
    var isMutiple = $(this).data("isMutiple")

    if (isMutiple != undefined && isMutiple){
      changeSubBranchMultiple(branch, newBranch, false)
    } else {
      changeSubBranch(branch, newBranch, false)
      branchAgents("",branch, false)
    }
  });
  if ($(".branch-dropdown").length) {
    branchDropdown = $(".branch-dropdown")
    var branch = $(branchDropdown).val();
    var newBranch = $(branchDropdown).data("target")
    selectedSubSranch = $(branchDropdown).data("selected")
    selectedAgent = $(".agent_selector").data("selected")
    var isMutiple = $(branchDropdown).data("isMutiple")
    if ($(".company_sub_branch_selector").length != 0){
      branchAgents(selectedSubSranch, branch, selectedAgent)
    }else{
      branchAgents("", branch, selectedAgent)
    }
    if (isMutiple != undefined && isMutiple){
      changeSubBranchMultiple(branch, newBranch, selectedSubSranch)
    }else {
      changeSubBranch(branch, newBranch, selectedSubSranch)
    }
  }

  $(document).on("click", ".select_all_parent", function(e) {
    $(".select_all_child").prop("checked", e.target.checked);
  });
});

$(document).ready(function(){
  $(".company_sub_branch_selector").on('change', function(){
    var sub_branch = $(this).val();
    branchAgents(sub_branch,"", false)
  });
  if ($(".company_sub_branch_selector").length) {
    branchDropdown = $(".branch-dropdown")
    selectedSubSranch = $(branchDropdown).data("selected")
    selectedAgent = $(".agent_selector").data("selected")
    branchAgents(selectedSubSranch, "",selectedAgent)
  }
})

function branchAgents(sub_branch_id, branch_id, selectedAgent,){
  if(branch_id || sub_branch_id) {
    $.ajax({
      url: '/fd-mis-accounts/select2_agent_options',
      type: 'GET',
      dataType: 'json',
      data: { 'sub_branch_id' : sub_branch_id, 'branch_id': branch_id},
      success: function(agents) {
        if (agents.status == 101 ){
          alert("Please select branch id or sub branch id.")
        } else {
          agent_options(agents, selectedAgent)
        }
      }
    });
  }
}

function agent_options(agents, selectedAgent){
  $('.agent_selector').empty();
  $('.agent_selector').append('<option>Select One</option>');
  for(var i = 0; i < agents.length; i++){
    if (selectedAgent != undefined && selectedAgent.length != 0 && selectedAgent != false && selectedAgent == agents[i]["id"]){
      $('.agent_selector').append('<option selected="selected" value=' + agents[i]["id"] + '>' +agents[i]["text"] + '</option>');
    } else {
      $('.agent_selector').append('<option value=' + agents[i]["id"] + '>' +agents[i]["text"] + '</option>');
    }
  }
}

$(document).ready(function(){
  $(".ledger-dropdown").on('change', function(){
    var ledger_name = $(this).val();
    var bms_id = $('option:selected', this).data('bms');

    if (ledger_name && bms_id) {
      $.ajax({
        url: `/settings/bank_manual_statements/${bms_id}`,
        method: "PATCH",
        dataType: "json",
        data: {ledger_id: ledger_name},
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          if (response.success) {
            toastr.success(response.success);
          } else {
            for (let i = 0; i < response.errors.length; i++) {
              toastr.error(response.errors[i]);
            }
          }
          location.reload();
        }
      });
    }
  });
});

$(document).ready(function(){
  $('#account_details_card').addClass('d-none');
  $("#payment_transaction_account_id").on('change', function(){
    var type = $('#account_type_detail').val();
    var id = $(this).val();
    if (type && id) {
      $.ajax({
        method: 'GET',
        url: '/deposit_accounts/account_info',
        dataType: 'json',
        data: { id: id, type: type },
        success: function (response) {
          $('#account_details_card').empty();
          var tableHtml =  '<h3 style="background-color: var(--background-theme-colour) !important; color: white; padding: 10px;">' + type.replace(/Account/, ' Account') + " " + "Info" + '</h3>' + '<table class="table table-striped">';
          if (jQuery.isEmptyObject(response)){
            $('#account_details_card').append(tableHtml);
            $('#account_details_card').append('<h4 class="text-center">' + "Data not found!" + '</h4>')
            $('#account_details_card').removeClass('d-none');
            return
          }
          $.each(response, function(key, value) {
            tableHtml += '<tr>';
            tableHtml += '<th>' + key.replace(/_/g," ") + '</th>';
            tableHtml += '<td>' + value + '</td>';
            tableHtml += '</tr>';
          });
          tableHtml += '</table>';
          $('#account_details_card').append(tableHtml);
          $('#account_details_card').removeClass('d-none');
        }
      });
    }
  });
});

$(document).ready(function () {
  var submitButton = $("#form-submit");
  if (submitButton.val() === "SUBMIT") {
    var requestedAmount = $("#loan_application_amount_requested").val();
    var minimumLoanAmt = submitButton.data('min-loan-amount');
    var maximumLoanAmt = submitButton.data('maximum-loan-amount');
    var totalSecurityValue = submitButton.data('total-security-value');
    var principalAmount = submitButton.data('principal-amount');
    var minLoanLimit = submitButton.data('min-loan-limit');
    var maxLoanLimit = submitButton.data('max-loan-limit');

    var adjustAmount = function (message) {
        submitButton.attr('type', 'button');
        submitButton.attr('data-target', '.bd-example-modal-md');
        submitButton.attr('data-toggle', 'modal');
        $('.modal-body p').text(message);
    };
  
    if (parseFloat(totalSecurityValue) > 0) {
      var maxAmt = parseFloat(totalSecurityValue) * maxLoanLimit / 100;
      var minAmt = parseFloat(totalSecurityValue) * minLoanLimit / 100;

      if (maxAmt > maximumLoanAmt) {
        adjustAmount("You are requesting an amount of " + parseFloat(totalSecurityValue) + ", but it exceeds the maximum limit set by the loan plan. Therefore, we are adjusting the amount to the maximum loan amount.");
      } else if (minAmt < minimumLoanAmt) {
        adjustAmount("You are requesting an amount of " + parseFloat(totalSecurityValue) + " However, the minimum loan amount for our loan plans is " + minimumLoanAmt + " Therefore, we have adjusted the approved amount to the minimum loan amount.");
      } else if (isNaN(maxAmt) && isNaN(minAmt)) {
        if (parseFloat(totalSecurityValue) > parseFloat(maximumLoanAmt)) {
          adjustAmount("Your requested amount is " + totalSecurityValue + ". However, for this loan plan, there is no specified minimum or maximum loan limit. Therefore, we have adjusted the approved amount to the maximum loan amount " + maximumLoanAmt + ". since your requested amount is greater than maximum loan amount for our loan plans.");
        } else if (parseFloat(totalSecurityValue) < parseFloat(minimumLoanAmt)) {
          adjustAmount("Your requested amount is " + totalSecurityValue + ". However, for this loan plan, there is no specified minimum or maximum loan limit. Therefore, we have adjusted the approved amount to the minimum loan amount " + minimumLoanAmt + ". since your requested amount is less than minimum loan amount for our loan plans.");
        } else {
          adjustAmount("Your requested amount is " + totalSecurityValue + ". However, for this loan plan, there is no specified minimum or maximum loan limit. Therefore, we have adjusted the approved amount to the requested amount " + totalSecurityValue + ".");
        }
      } else {
        adjustAmount("Your request for a loan amount of " + parseFloat(totalSecurityValue) + " has been reviewed. However, based on our assessment, we have adjusted the approved amount to the maximum loan limit " + maxLoanLimit + "% allowable loan amount, considering factors such as the principal amount of " + principalAmount + ".");
      }
    } else if (parseFloat(requestedAmount) > parseFloat(maximumLoanAmt)) {
      adjustAmount("You are requesting an amount of " + requestedAmount + " However, the maximum loan amount for our loan plans is " + maximumLoanAmt + " Therefore, we have adjusted the approved amount to the maximum loan amount.");
    } else if (parseFloat(requestedAmount) < parseFloat(minimumLoanAmt)) {
      adjustAmount("You are requesting an amount of " + requestedAmount + " However, the minimum loan amount for our loan plans is " + minimumLoanAmt + " Therefore, we have adjusted the approved amount to the minimum loan amount.");
    }
  }
});

$(document).ready(function(){
  $("#settlement_btn").click(function(){
    $("#settlement-value").val("true");
  });
});

$(document).ready(function(){
  $('.approve-btn, .reject-btn').click(function(){
    var parent_div_value = $(this).parent().parent();
    var reject_class = parent_div_value.children().eq(2).children().eq(0).attr('disabled', true);
    var approve_class = parent_div_value.children().eq(1).children().eq(0).attr('disabled', true);
  });
});
